.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
	pointer-events: auto;
  }
  
  .modal-content {
	background-color: rgba(0, 0, 0, 0.5);
	padding: 20px;
	border-radius: 8px;
	max-width: 80%;
	text-align: center;
  }
  
  .modal-content h2 {
	margin-bottom: 10px;
	color: white;
	font-family: 'Roboto';
	font-weight: 300;
  }
  
  .modal-content button {
	padding: 8px 16px;
	background-color: #ffffff;
	color: #292929;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	pointer-events: auto;
  }
  
  .modal-content button:hover {
	background-color: #0056b3;
  }
  
  /* Apply overflow:hidden when modal is open */
  .modal-open {
	overflow: hidden;
  }
  