.btn-container {
	display:flex;
	flex-direction: column;
	gap: 8px;
	background-color: #fff;
	border-radius: 8px 0 8px;
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 100;
}

button{
	border: none;
	color: #737373;
	background-color: #fff;
	padding: 10px;
	cursor: pointer;
}
.preview_pic{
	display: none;
	height:100%;
	max-width: 100%;
}

@media screen and (max-width: 400px){
	.zoom-pic{
		width: 100%;
	}
}

@media screen and (max-width: 950px) {
	.btn-container{
		display: none;
	}
	.zoom-pic{
		display: none;
	}
	.preview_pic{
		display: block;
	}
}
